import React from "react";
import "./produk.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import highlight from "../../asset/highlight/highlight";
import prdk from "./produk.json";
import Cardrumah from "../../component/cardrumah/cardrumah";
import delrey from "../../asset/delrey/delrey";
import tekno from "../../asset/tekno/tekno";
import theloop from "../../asset/theloop/theloop";
import latinos from "../../asset/latinos/latinos";
import westpark from "../../asset/westpark/westpark";
import vanya from "../../asset/vanya/vanya";
import northpoin from "../../asset/northpoin/nortpoin";
import cove from "../../asset/cove/cove";
import cascade from "../../asset/cascade/cascade";
import akasa from "../../asset/akasa/akasa";
const imageMap = {
  akasa,
  cascade,
  cove,
  northpoin,
  vanya,
  westpark,
  latinos,
  theloop,
  tekno,
  delrey,
  // Add other image arrays here if you have more sets
};

const Produk = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,
          lazyload: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="produk">
      <div className="container-produk">
        <div className="header-produk">
          Fulfill Your Daily Needs Within Reach
        </div>
        <div className="carousel-highlight">
          <Slider {...settings}>
            {highlight.map((image, index) => (
              <div>
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt="highlight"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="cardhome-container">
          {prdk.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Produk;
