import React from "react";
import "./promo.scss";
import Cardrumah from "../../component/cardrumah/cardrumah";
import northridge from "../../asset/northridge/northridge";
import prm from "./promo.json";
const imageMap = {
  northridge,
};

const Promo = () => {
  return (
    <div id="promo" className="container-promo">
      <div className="dividera"></div>
      <div className=" container-listpromo">
        <div className="header-nwlaunch">New Launching Ruko Northridge</div>
        <div className="cardhome-container">
          {prm.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
      <div className="dividerb" />
    </div>
  );
};

export default Promo;
